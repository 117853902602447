import React, { useState } from "react";
import "../css/makeTable.css";
import MerchantInnerDetails from "./Merchant/MerchantInnerDetails.js";
import MerchantCreditReport from "./Merchant/creditReport.js"
import MerchantsOrder from "./Merchant/MerchantsOrder"
import MerchantRepaymentDetails from "./Merchant/MerchantRepaymentDetails.js"
import MerchantApiLogsDetails from "./Merchant/apiLogsDetails.js"

function LeadDetails() {
  const [activeTab, setActiveTab] = useState("orders");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  return (
    <div>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === "merchantDetails" ? "active" : ""}`}
            id="merchantDetails-tab"
            data-bs-toggle="tab"
            data-bs-target="#merchantDetails"
            type="button"
            role="tab"
            aria-controls="merchantDetails"
            aria-selected={activeTab === "merchantDetails"}
            onClick={() => handleTabClick("merchantDetails")}
          >
            Merchant Details
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === "creditReport" ? "active" : ""}`}
            id="creditReport-tab"
            data-bs-toggle="tab"
            data-bs-target="#creditReport"
            type="button"
            role="tab"
            aria-controls="creditReport"
            aria-selected={activeTab === "creditReport"}
            onClick={() => handleTabClick("creditReport")}
          >
            Credit Report
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === "orders" ? "active" : ""}`}
            id="orders-tab"
            data-bs-toggle="tab"
            data-bs-target="#orders"
            type="button"
            role="tab"
            aria-controls="orders"
            aria-selected={activeTab === "orders"}
            onClick={() => handleTabClick("orders")}
          >
            Orders
          </button>
        </li>
      </ul>
      <div>
        <p></p>
      </div>
      <div className="tab-content" id="myTabContent">
        {activeTab === "merchantDetails" && (
          <div className="tab-pane fade show active" id="merchantDetails" role="tabpanel" aria-labelledby="merchantDetails-tab">
            <MerchantInnerDetails /> 
          </div>
        )}
        {activeTab === "creditReport" && (
          <div className="tab-pane fade show active" id="creditReport" role="tabpanel" aria-labelledby="creditReport-tab">
            <MerchantCreditReport />
          </div>
        )}
        {activeTab === "orders" && (
         <MerchantsOrder />
        )}
        {activeTab === "contact" && (
          <div className="tab-pane fade show active" id="contact" role="tabpanel" aria-labelledby="contact-tab">
            <MerchantRepaymentDetails />
          </div>
        )}
        {activeTab === "api" && (
          <div className="tab-pane fade show active" id="api" role="tabpanel" aria-labelledby="api-tab">
            <MerchantApiLogsDetails />
          </div>
        )}
      </div>
    </div>
  );
}

export default LeadDetails;
