import React, { useMemo, useState, useEffect } from "react";
import "../../css/makeTable.css";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import { getData, postData } from "../../store/utils";
import Loader from "../../component/Loader";
import Modal from "react-modal";
import { FaRegTimesCircle } from "react-icons/fa";
import { formatDateTimeSecond } from "../../component/common";
import Swal from "sweetalert2";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
  },
};

const MerchantInnerDetails = () => {
  const [merchantPersonalData, setMerchantPersonalData] = useState([]);
  const [merchantBankData, setMerchantBankData] = useState();
  const searchParams = new URLSearchParams(window.location.search);
  const [merchantAddressData, setMerchantAddressData] = useState();
  const [merchantDocumentData, setMerchantDocumentData] = useState();
  const [merchantPhotoData, setMerchantPhotoData] = useState();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [modalImageURl, setModalImageURl] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const userIdParams = searchParams.get("userId");
  const userId = parseInt(userIdParams)

  let userData = localStorage.getItem("USER");
  let parseData = JSON.parse(userData)
  let lenderId = parseData.lenderId

  const closeModal = () => {
    setIsOpen(false);
  };

  function formatDate(dob) {
    const date = new Date(dob);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
  
    return `${day}-${month}-${year}`;
  }

  async function merchantGetData() {
    let filterData = {
      userId: userId,
      financierId: lenderId
    };
    
      setIsLoadingOn(true);
      const tempData = await postData(`lender/merchantsInfo`, filterData);     
      setIsLoadingOn(false);
      if (tempData.statusCode == 200) {
        setMerchantPersonalData(tempData?.data);
      }
      else{
        Swal.fire({
          title:"Error!",
          text:tempData?.data?.message || "Something went wrong",
        })
    }
  }

  useEffect(() => {
      merchantGetData();
  }, []);

  // ------------- Merchant Bank Data --------------------------

  async function merchantGetBankData() {
    let filterData = {
      userId: userId
    };
    setIsLoadingOn(true);
    const tempData = await postData(`lender/merchantsBankInfo`, filterData);
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setMerchantBankData(tempData.data[0]);
    }
    // else{
    //   Swal.fire({
    //     title:"Error!",
    //     text:tempData?.data?.message || "Something went wrong",
    //   })
    // }
  }

  useEffect(() => {
    merchantGetBankData();
  }, []);

  async function merchantGetAddressData() {
    let filterData = {
      userId: userId
    };
    setIsLoadingOn(true);
    const tempData = await postData(`lender/merchantsShopInfo`, filterData);
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setMerchantAddressData(tempData.data[0]);
    }
    else{
      Swal.fire({
        title:"Error!",
        text:tempData?.data?.message || "Something went wrong",
      })
  }
  }

  useEffect(() => {
    merchantGetAddressData();
  }, []);

  async function merchantGetDocumentData() {
    let filterData = {
      userId: userId
    };
    setIsLoadingOn(true);
    const tempData = await postData("lender/merchantsDocuments", filterData);
    setIsLoadingOn(false);
    let merchantDocumentPhoto = [];
    let merchantShopPhoto = [];
    if (tempData.statusCode == 200) {
      for (let i = 0; i < tempData.data.length; i++) {
        const element = tempData.data[i];
        if (element.tableReference == "USER_SHOP") {
          merchantShopPhoto.push(element);
        } else if (element.tableReference !== "ORDER") {
          merchantDocumentPhoto.push(element);
        }
      }
      setMerchantDocumentData(merchantDocumentPhoto);
      setMerchantPhotoData(merchantShopPhoto);
    }
    else{
      Swal.fire({
        title:"Error!",
        text:tempData?.data?.message || "Something went wrong",
      })
    }
  }

  useEffect(() => {
    merchantGetDocumentData();
  }, []);

  const imageViewModal = (filePath) => {
    setModalImageURl(filePath);
    setIsOpen(true);
  };

  const data = useMemo(
    () => [
      {
        id: 1,
        name: "John Doe",
        age: 25,
        city: "New York",
        mobileNo: "9988776786",
      },
      {
        id: 2,
        name: "Jane Smith",
        age: 30,
        city: "London",
        mobileNo: "9988776786",
      },
      {
        id: 3,
        name: "Bob Johnson",
        age: 35,
        city: "Paris",
        mobileNo: "9988776786",
      },
      // Add more data as needed
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: "userShopId",
        Cell: ({ cell }) => {
          return (
            <>
              <span style={{ fontWeight: "600" }}>{cell.row.index + 1}</span>
            </>
          );
        },
      },
      {
        Header: "Document Category",
        accessor: "tableReference",
      },
      {
        Header: "Document Type",
        accessor: "documentName",
      },
      {
        Header: "Image",
        accessor: "filePath",
        Cell: ({ cell }) => {
          const fileType = cell.row.original.fileType;
          return (
            <>
              {fileType == "image" ? (
                <div
                  style={{ textAlign: "center", cursor: "pointer" }}
                  onClick={() => imageViewModal(cell.row.original)}
                >
                  <img
                    style={{ maxHeight: 50 }}
                    src={cell.row.original.filePath}
                  />
                </div>
              ) : (
                <a href={cell.row.original.filePath} download>
                  <img style={{ maxHeight: 50 }} src="../assets/img/pdf.png" />
                </a>
              )}
            </>
          );
        },
      },

      {
        Header: "Uploaded Date",
        accessor: "createdAt",
        Cell: ({ cell }) => {
          return <>{cell.row.original.createdAt}</>;
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );
  return (
    <>
      {isLoadingOn ? <Loader /> : ""}
      <div className="accordion" id="accordionPanelsStayOpenExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseOne"
              aria-expanded="true"
              aria-controls="panelsStayOpen-collapseOne"
            >
              Merchant Personal Details
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseOne"
            className="accordion-collapse collapse show"
          >
            {merchantPersonalData &&
              merchantPersonalData.map((item, index) => (
                <>
                <div className="accordion-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="bankTableDetailsData">
                    <table>
                      <tr>
                        <th>Name</th>
                        <td>{item?.userName}</td>
                      </tr>

                      <tr>
                        <th>Mobile/Alternate Mobile</th>
                        <td>
                        {item?.mobileNumber
                                ? item?.mobileNumber
                                : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Company Name</th>
                        <td>{item?.shopName}</td>
                      </tr>

                      <tr>
                        <th>Status</th>
                        <td>
                        {item && (
                              <h5
                                className={`${
                                  item?.userStatusName == "Active"
                                    ? "activeStatus"
                                    : item?.userStatusName == "Suspended"
                                    ? "SuspendedStatus"
                                    : "defaluStatus"
                                }`}
                              >
                              {item?.userStatusName}</h5>
                            )}
                        </td>
                      </tr>

                      <tr>
                        <th>Created At</th>
                        <td>{item && formatDateTimeSecond(item?.createdAt)}</td>
                      </tr>
                      <tr>
                        <th>User Reference Number</th>
                        <td>{item?.userReferenceNumber}</td>
                      </tr>

                      <tr>
                        <th>Merchant Reference Number</th>
                        <td>{item?.merchantReferenceNumber}</td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="bankTableDetailsData">
                    <table>
                      <tr>
                        <th>PAN Number</th>
                        <td>
                          {
                            item && item?.panNumber && item?.panNumber !== 'null' && item?.panNumber !== null
                            ? "xxxxxx" + item?.panNumber?.slice(-4)
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>GST Number</th>
                        <td>
                        {item && item?.gstNumber && item?.gstNumber !== 'null' && item?.panNumber !== null
                                ? "XXXXXX" + item.gstNumber?.slice(-4)
                                : "-"}
                        </td>
                      </tr>

                      {/* <tr>
                        <th>Onboarding Status</th>
                        <td>
                        {item && item?.creditLimit > 0
                                ? `Limit Assigned and ${item?.onboardingStatusName}`
                                : `Limit Not Assigned and ${item?.onboardingStatusName}`}
                        </td>
                      </tr> */}

                      <tr>
                        <th>Birth Date</th>
                        <td>
                        {item && item?.dob ? formatDate(item?.dob) : "-"}
                        </td>
                      </tr>

                      <tr>
                        <th>Gender</th>
                        <td>{item && item?.gender ? item?.gender : "-"}</td>
                      </tr>
                      <tr>
                        <th>Onboarded As</th>
                        <td>{item && item?.onboardedAs ? item?.onboardedAs : "-"}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
                </>
                
              ))}
          </div>
        </div>
        

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseFour"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseFour"
            >
              Present address of the Shop
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseFour"
            className="accordion-collapse collapse"
          >
            <div className="accordion-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="bankTableDetailsData">
                    <table>
                      <tr>
                        <th>Address Reference Number</th>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>Line 1</th>
                        <td>
                          {merchantAddressData
                            ? merchantAddressData.address1
                            : ""}
                        </td>
                      </tr>

                      <tr>
                        <th>Land Mark</th>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>City</th>
                        <td>
                          {merchantAddressData ? merchantAddressData.city : ""}
                        </td>
                      </tr>

                      <tr>
                        <th>Tag</th>
                        <td>-</td>
                      </tr>
                      <tr>
                        <th>Residence Type</th>
                        <td>-</td>
                      </tr>

                      <tr>
                        <th>Is Verified</th>
                        <td>-</td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="bankTableDetailsData">
                    <table>
                      <tr>
                        <th>Room Number</th>
                        <td>
                          {merchantAddressData
                            ? merchantAddressData.shopNumberAndBuildingName
                            : ""}
                        </td>
                      </tr>

                      <tr>
                        <th>Line 2</th>
                        <td>
                          {merchantAddressData
                            ? merchantAddressData.address2
                            : ""}
                        </td>
                      </tr>

                      <tr>
                        <th>Pincode</th>
                        <td>
                          {merchantAddressData
                            ? merchantAddressData.shopPinCode
                            : ""}
                        </td>
                      </tr>

                      <tr>
                        <th>State</th>
                        <td>
                          {merchantAddressData ? merchantAddressData.state : ""}
                        </td>
                      </tr>

                      <tr>
                        <th>Address Verification Status</th>
                        <td>-</td>
                      </tr>
                      <tr>
                        <th>Is Active</th>
                        <td>-</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseFive"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseFive"
            >
              Merchant Document Details
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseFive"
            className="accordion-collapse collapse"
          >
            <div className="accordion-body">
              {merchantDocumentData && (
                <Table columns={columns} data={merchantDocumentData} />
              )}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseSix"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseSix"
            >
              Shop Photo and Video
            </button>
          </h2>
          <div
            id="panelsStayOpen-collapseSix"
            className="accordion-collapse collapse"
          >
            <div className="accordion-body">
              {merchantPhotoData && (
                <Table columns={columns} data={merchantPhotoData} />
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        {modalImageURl && modalImageURl.fileType == "image" && (
          <div className="modalUserImage">
            <img
              src={modalImageURl && modalImageURl.filePath}
              alt="modalImage"
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default MerchantInnerDetails;
// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    //state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy, //sort data
    usePagination //use pagination
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  //const firstPageRows = rows.slice(0, 10);

  return (
    <>
      <div>
        <table {...getTableProps()} className="dashboardTable">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="tableHeader"
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="tableTd">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="row m-0">
          <div className="pagination col-lg-12">
            <div className="col-lg-2">
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {">"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>{" "}
            </div>
            <div className="col-lg-2">
              <span>
                Total Records <strong>{rows.length}</strong>{" "}
              </span>
            </div>
            <div className="col-lg-2">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
            </div>
            <div className="col-lg-3">
              <span>
                Go to page{" "}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  className="gotoPage"
                />
              </span>{" "}
            </div>
            <div className="col-lg-3">
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="selectDisplayNoOfRecords"
              >
                {[5, 10, 20, 30, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <br />
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
