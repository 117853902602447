import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { getData, postData } from "../../store/utils";
import { formatDate, formatDateTimeString, formatNumberWithCommasAndDecimals } from "../../component/common";
import Loader from "../../component/Loader";
import Modal from "react-modal";
import { FaRegTimesCircle } from "react-icons/fa";
import Swal from "sweetalert2";
import Select from "react-select";

const MerchantsOrder = () => {
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const [merchantUserData, setMerchantUserData] = useState();
  const  [repaymentDeatailsTable, setRepaymentDetailsTable] = useState([])
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [IsSuspended, setIsSuspended] = useState(false);
  const [showAleart, setshowAleart] = useState(false);
  const [remark, setRemark] = useState("");
  const [IsReject, setIsReject] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState();
  const [rejectedStatus, setRejectedStatus] = useState();
  const orderIdParams = searchParams.get("orderId");
  const orderId = parseInt(orderIdParams)
  const orderType = searchParams.get("orderType");
  const [optionValSel, setOptionValSel] = useState("")

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      overflow: "none",
      padding: "0px",
      width: "25%",
    },
  };

  let loginUserData = localStorage.getItem("USER")
  let parseData = JSON.parse(loginUserData)
  let lenderId = parseData.lenderId

  const getorderDetails = async (orderId) => {
    
    let filterData = {
    lenderId  : lenderId,
    // pageSize : 10,
    // page: 1,
    orderType, orderType,
    orderId : orderId
    }
    setIsLoadingOn(true);
    const tempData = await postData("lender/orderDetails", filterData);
    setIsLoadingOn(false);
    if (tempData.statusCode === 200) {
      setMerchantUserData(tempData?.data);
    } else{
      Swal.fire({
        icon: "error",
        title:"Error!",
        text:tempData?.data?.message
      })
    }
  };
  const getRepaymentDetails = async (orderId) => {
    let filterData = {
      orderId : orderId
      }
      setIsLoadingOn(true);
      const tempData = await postData("lender/merchantsRepayment", filterData);
      setIsLoadingOn(false);
      if (tempData.statusCode === 200) {
        setRepaymentDetailsTable(tempData);
      }
  }

  const closeModal = () => {
    setIsOpen(false);
  };

  const updateStatus = () => {
    setIsOpen(true);
  };
  const updateOrderStatus = async (orderId) => {
    console.log(orderId, optionValSel, remark, "Comment data")
    
    let filterData = {
      orderId: orderId,
      status: optionValSel,
      lenderComment : remark,
      referenceTable : orderType, 
      referenceId: orderId 
    };
    setIsLoadingOn(true);
    const tempData = await postData("lender/updateOrderStatus", filterData);
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setIsOpen(false);
      getorderDetails(orderId);
      Swal.fire({
        icon: "success",
        title:"Success",
        text:tempData?.data
      })
    }
    else{
      Swal.fire({
        icon: "error",
        title:"Error!",
        text:tempData?.data?.message || "Something went wrong!!!"
      })
    }
  };
  const approvedHandle = () => {
    setIsReject(false);
    setIsSuspended(true);
  }

  const Rejecthandle = () => {
    setIsReject(true);
    setIsSuspended(false);
  }
  const handleSelectChange = (e) => {
    const optionVal = e.value;
    setOptionValSel(optionVal)
}

  useEffect(() => {
    getRepaymentDetails(orderId)
    if (orderId) {
      getorderDetails(orderId);
    }
  }, [orderId]);
  return (
    <div>
      {isLoadingOn ? <Loader /> : ""}
      <div
        className="tab-pane fade show active"
        id="profile"
        role="tabpanel"
        aria-labelledby="orders-tab"
      >
        <div className="accordion" id="accordionPanelsStayOpenExample">
          {merchantUserData &&
            merchantUserData.map((data, index) => (
              <div key={index} className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseOne"
                  >
                    Order {index + 1}
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseOne"
                  className="accordion-collapse collapse show orderdata"
                >
                  <div className="accordion-body">
                    <div className="bankDetailsMerchant">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-1">
                            <div className="merchantNameDetails">
                              <p>ID</p>
                              <h5
                                style={{ color: "blue", cursor: "pointer" }}
                              >
                                {data?.orderCode}
                              </h5>
                            </div>
                          </div>

                          <div className="col-md-2">
                            <div className="merchantNameDetails">
                              <p>Approval Status</p>
                              <h5
                                className={`badge badge-pill text-white ${
                                  data?.lenderApprovalStatus === "Approved"
                                    ? "badge-success"
                                    : data?.lenderApprovalStatus === "Rejected"
                                    ? "badge-danger"
                                    : data?.lenderApprovalStatus === "Exception"
                                    ? "badge-warning"
                                    : data?.lenderApprovalStatus === "Pending"
                                    ? "badge-primary"
                                    : "badge-primary"
                                }`}
                              >
                                {data?.lenderApprovalStatus}
                              </h5>
                            </div>
                          </div>

                          <div className="col-md-2">
                            <div className="merchantNameDetails">
                              <p>Shop Name</p>
                              <h5>{data?.shopName}</h5>
                            </div>
                          </div>

                          <div className="col-md-1">
                            <div className="merchantNameDetails">
                              <p>Amount</p>
                              <h5>₹ {data?.disbursedAmount}/-</h5>
                            </div>
                          </div>

                          <div className="col-md-2">
                            <div className="merchantNameDetails">
                              <p>Order Date</p>
                              <h5>
                                {data?.createdDate && formatDate(data?.createdDate) ? data?.createdDate || formatDate(data?.createdDate)  : "-"}
                              </h5>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="merchantNameDetails">
                              <p>Disbursement Date</p>
                              <h5>{data?.disbursedDate && formatDate(data?.disbursedDate) ? data?.disbursedDate || formatDate(data?.disbursedDate) : "-"}</h5>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="btn-group">
                              <button type="button" onClick={()=>updateStatus(orderId)} className={`btn btn-primary actionbtn ${data?.lenderApprovalStatus === "Approved" || data?.lenderApprovalStatus === "Rejected" || data?.lenderApprovalStatus === "Exception"  ? "d-none" : "" }`}>
                                Action
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* -------------------------------------------------------------------------------------- */}
                        {data?.lenderApprovalStatus === "Approved" && repaymentDeatailsTable?.data.length > 0 &&

                        <div>
                          <div className="row mt-3">
                            <div className="col-md-2">
                                <div className="merchantNameDetails">
                                  <h5
                                      style={{ color: "blue"}}
                                    >
                                      Repayment History
                                    </h5>
                                </div>
                              </div>
                          </div>
                              
                          <table width="100%" className="table table-bordered dashboardTable">
                            <thead>
                              <tr>
                                <th>Sr. No.</th>
                                <th>Loan Id</th>
                                <th>Shop Owner Name</th>
                                <th>Shop Name</th>
                                <th>Disbursed Amount</th>
                                <th>Repayment Amount</th>
                                <th>Record Date</th>
                                <th>EMI Date</th>
                                <th>Payment Status</th>
                              </tr>
                            </thead>
                            <tbody>
                            {repaymentDeatailsTable?.data?.map((item) => {
                              return <tr key={item?.orderId}>
                                      <td>{index + 1}</td>
                                      <td>{item?.loanId}</td>
                                      <td>{item?.userName}</td>
                                      <td>{item.shopName}</td>
                                      <td>{item.totalDisbursedAmount}</td>
                                      <td>{item.amountWithExtraCharges}</td>
                                      {/* <td>{item.emiDate} / Time</td> */}
                                      <td>-</td>
                                      <td>{item.emiDate}</td>
                                      <td>{item.statusName}</td>
                                    </tr>
                            })}
                              
                            </tbody>
                          </table>
                        </div>
                        }    
                            
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
         {merchantUserData && merchantUserData.length == 0 && <p style={{fontSize:22, marginTop:20, fontWeight:"600"}}>No Data Found</p>}
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        <h4 className="pleaseSelectStatus">Please Select Order Status</h4>
        <div className="modalUserStatus">
          <div className="mb-3 ordermain">
            {/* <div className="wrapper_btnselt"> */}
              {/* <button
                onClick={approvedHandle}
                htmlFor="option-3"
                className={
                  IsSuspended
                    ? "suspendedMng option option-3"
                    : "option option-3"
                }
              >
                <span onClick={() => {
                    setApprovalStatus("Approved");
                  }}>Approved</span>
              </button>

              <button
                onClick={Rejecthandle}
                htmlFor="option-2"
                className={
                  IsReject ? "rejectlisth option option-2" : "option option-2"
                }
              >
                <span onClick={() => {
                    setApprovalStatus("Rejected");
                  }}>Rejected</span>
              </button> */}
              <label htmlFor="exampleFormControlTextarea1">Approval Status</label>
              <Select
                placeholder="Select Status"
                name="role"
                options={[
                  {label:"Approved", value:"Approved"},
                  {label:"Rejected", value:"Rejected"},
                  {label:"Exception", value:"Exception"}
                ]}
                onChange={(e) => handleSelectChange(e)}
            />
              
            {/* </div> */}
          </div>
          <div className="remardi">
            <div className="form-group">
              <label htmlFor="exampleFormControlTextarea1">Financer Comment</label>
              <textarea
                onChange={(event) => {
                  setRemark(event.target.value);
                }}
                value={remark}
                className="form-control textacont"
                rows="3"
              ></textarea>
              {showAleart ? (
                <p className="requiredRemark">Remark is required...</p>
              ) : null}
            </div>
          </div>
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <button className="btn btn-success"  onClick={()=> updateOrderStatus(orderId)}>Submit</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default MerchantsOrder;
