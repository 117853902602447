import React, { useState } from 'react';
import './Login.css';
import { useHistory } from 'react-router-dom';

function LoginPage({ handleLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin(username, password, history);
  };

  return (
    <div className='loginBodyPage'>
      <div className="LoginPageContainer">
        <div className="LoginPageInnerContainer">
          <div className="ImageContianer">
            <img src="https://i.imgur.com/MYZd7of.png" className="GroupImage" />
          </div>
          <div className="LoginFormContainer">
            <div className="LoginFormInnerContainer">
              <div className="LogoContainer">
                {/* <img
                  src="https://www.pngkey.com/png/full/529-5291672_sample-logo-png-transparent-background.png"
                  className="logo"
                /> */}
                <div className="logo">
                <img src="../assets/img/Daqi_logo.png" className='logo' />
                  <h1
                    style={{
                      color: "#2e1f7a",
                      fontWeight: "bold",
                      marginBottom: 30,
                      fontSize: "30px",
                      marginLeft: 20,
                    }}
                  >
                    DAQI
                  </h1>
                </div>
               
              </div>
              <header className="header">Log in</header>
              <header className="subHeader">
                Welcome to <b>DAQI</b> Please Enter your Details
              </header>
              <form onSubmit={handleSubmit}>
                <div className="inputContainer">
                  <label className="label" htmlFor="emailAddress">
                    <img
                      src="https://i.imgur.com/Hn13wvm.png"
                      className="labelIcon"
                    />
                    <span>Email Address*</span>
                  </label>
                  <input type="text" placeholder="Enter your Email Address" required=""
                    value={username} className="input"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="inputContainer">
                  <label className="label" htmlFor="emailAddress">
                    <img
                      src="https://i.imgur.com/g5SvdfG.png"
                      className="labelIcon"
                    />
                    <span>Password*</span>
                  </label>
                  <input type="password" placeholder="Enter your Password" required=""
                    value={password} className="input"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                {/* <div className="OptionsContainer">
                  <div className="checkboxContainer">
                    <input type="checkbox" id="RememberMe" className="checkbox" />{" "}
                    <label htmlFor="RememberMe">Remember me</label>
                  </div>
                  <a href="#" className="ForgotPasswordLink">
                    Forgot Password?
                  </a>
                </div> */}
                <button className="LoginButton" type='submit'>Login</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default LoginPage;