import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Lefbar from "./Lefbar";
import LoginPage from "../Login/LoginPage";
import LeadDetailsPage from "../Leads/LeadDetailsPage.js";
import Loader from "./Loader";
import {postDataWithoutToken } from "../store/utils";
import FundInvoiceList from "../Users/UsersList.js";
import Leads from "../Leads/Leads.js";
import UsersList from "../Users/UsersList.js";
import axios from "axios";
import { apiHost } from "../constant.js";
function Routes() {
  const [isAuthenticated, setIsAuthenticated] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);

  useEffect(() => {
    const storedAuthState = localStorage.getItem("isAuthenticated");
    if (storedAuthState) {
      setIsAuthenticated(storedAuthState);
    }
    setIsLoading(false);
  }, []);

  const handleLogin = async (userId, password, history) => {
    let tempDocument = {
      email: userId,
      password: password,
      platform: "MIS-WEB",
      userType: "LENDER",
    };

    setIsLoading(true);
    const tempData = await postDataWithoutToken(
      `lender/login`,
      tempDocument
    );
    setIsLoading(false);    
    if (
      tempData.statusCode === 200 &&
      tempData.data.accessToken &&
      tempData.data.accessToken.length > 0
    ) {
      localStorage.setItem("isAuthenticated", tempData.data.accessToken);
      localStorage.setItem("jwtRefreshToken", tempData.data.refreshToken);
      const user = tempData.data?.user;
      if (user) {
        setIsAuthenticated(tempData.data.accessToken);
        localStorage.setItem("USER", JSON.stringify(user));
        const isAuthenticatedData = localStorage.getItem("isAuthenticated");
        if (isAuthenticatedData && isAuthenticatedData.length > 0) {
          history.push("/");
        } else {
          alert("Unauthorized User");
          history.push("/login");
        }
      }
    } else {
      setIsAuthenticated("");
      localStorage.setItem("isAuthenticated", "");
      localStorage.setItem("jwtRefreshToken", "");
      alert("Invalid username or password");
    }
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };

  // const handleLogout = () => {
  //   setIsAuthenticated("");
  //   localStorage.removeItem("isAuthenticated");
  //   localStorage.removeItem("jwtRefreshToken");
  //   localStorage.removeItem("USER");
  // };

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem("isAuthenticated");
  
      if (!token) {
        console.log("No token found, redirecting to login.");
        window.location.href = '/login';
        return;
      }
      const logoutUrl = `${apiHost.baseURL}lender/logout?accessToken=${token}`;
  
      // Make a GET request to the logout URL
      await axios.get(logoutUrl);
      
      setIsAuthenticated("");
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("jwtRefreshToken");
      localStorage.removeItem("USER");
      window.location.href = '/login';
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isLoading ? (
          <Loader />
        ) : isAuthenticated && isAuthenticated.length > 0 ? (
          <>
            <Component {...props} />
          </>
        ) : (
          <LoginPage handleLogin={handleLogin} />
        )
      }
    />
  );

  return (
    <Router>
      <div className="AppContainer">
        {isAuthenticated &&
          isAuthenticated !== undefined &&
          isAuthenticated.length > 0 && (
            <div
              className="asideMenuBarTab"
              style={{ width: isSidebarCollapsed ? "70px" : "" }}
            >
              <Lefbar
                handleLogout={handleLogout}
                toggleSidebar={toggleSidebar}
                isSidebarCollapsed={isSidebarCollapsed}
              />
            </div>
          )}

        <main className="main-content">
          <Switch>
            <Route path="/login">
              {isAuthenticated &&
              isAuthenticated !== undefined &&
              isAuthenticated.length > 0 ? (
                <Redirect to="/" />
              ) : (
                <LoginPage handleLogin={handleLogin} />
              )}
            </Route>
            {/* <Route path="/ForgetPassword"><ForgetPassword /></Route> */}
            <PrivateRoute path="/" exact component={Leads} />
            <PrivateRoute path="/Leads" component={Leads} />
            <PrivateRoute path="/LeadDetailsPage" component={LeadDetailsPage}/>
            <PrivateRoute path="/UsersList" component={UsersList} />
            
          </Switch>
        </main>
      </div>
    </Router>
  );
}

export default Routes;
