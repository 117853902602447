import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import "../css/makeTable.css";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { getData, postData } from "../store/utils";
import Loader from "../component/Loader";
import TopShowMessage from "../component/TopShowMessage";
import { formatDateTimeString, formatNumberWithCommasAndDecimals } from "../component/common";
import { QueryClientProvider, QueryClient } from "react-query";
import Swal from "sweetalert2";
import ReactTable from "../pages/ReactTable";
import { useDebounce } from 'use-debounce';
import Modal from "react-modal";
import {
  FaList,
  FaRegFileExcel,
  FaRegFilePdf,
  FaFileInvoiceSolid,
  FaRegTimesCircle,
  FaTimes,
  FaTimesCircle,
  FaUserCog,
} from "react-icons/fa";
import { TbFileInvoice } from "react-icons/tb";


const queryClient = new QueryClient();

function Leads() {
  const statusName = {
    10: "Decline ",
    11: "Delete",
    14: "Duplicate",
    5: "Approved",
  };

  const [merchantUserData, setMerchantUserData] = useState([]);
  const [filteredData, setFilteredData] = useState([])

  const [merchantRefrenceType, setMerchantRefrenceType] = useState();
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [statusModalIsOpen, setStatusModalIsOpen] = React.useState(false);
  const [orderQuotationOpen, setOrderQuotationOpen] = React.useState(false);
  const [showAleartRemark, setshowAleartRemark] = useState(false);
  const [remark, setRemark] = useState("");
  const [IsAccept, setIsAccept] = useState(false);
  const [IsReject, setIsReject] = useState(false);
  const [IsApproved, setIsApproved] = useState(false);
  const [IsDuplicate, setIsDuplicate] = useState(false);
  const [IsSuspended, setIsSuspended] = useState(false);
  const [showTopMessage, setShowTopMessage] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState();
  const [userOrderId, setUserOrderId] = useState();
  const [userUserId, setUserUserId] = useState();
  const [orderQuotationData, setOrderQuotationData] = useState();
  const [statusApproved, setStatusApproved] = useState(false);
  const [quotationUserId, setquotationUserId] = useState([]);
  const [selectedImagesBase64, setSelectedImagesBase64] = useState();
  const [base64Image, setBase64Image] = useState("");
  const [shopUserId, setShopUserId] = useState();
  const [optionStatusType, setOptionStatusType] = useState();
  const [selectedStatusOption, setSelectedStatusOption] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(0); // Page index starts from 0
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [errorMessage, setErrorMessage] = useState("");
  const [sortBy, setSortBy] = useState(""); 
  const [orderBy, setOrderBy] = useState("");
  const [debouncedValue] = useDebounce(searchBoxValue, 1000); // 500ms debounce delay
  const [closeBtn, setCloseBtn] = useState(false)
  const [showAleart, setshowAleart] = useState(false);
  const [optionValSel, setOptionValSel] = useState("")

  const userRole = localStorage.getItem("USER");
  let userData = localStorage.getItem("USER");
  let parseData = JSON.parse(userData)
  let lenderId = parseData.lenderId

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      overflow: "none",
      padding: "0px",
    },
  };

  const statusModalOpen = (user) => {
    if (user.statusName == "Processing") {
      setStatusApproved(true);
    } else {
      setStatusApproved(false);
    }
    setUserOrderId(user.orderId);
    setUserUserId(user.userId);
    setIsOpen(true);
  };

  const inputRef = useRef();

  const data = useMemo(
    () => [
      {
        id: 1,
        name: "John Doe",
        age: 25,
        city: "New York",
        mobileNo: "9988776786",
      },
    ],
    []
  );
  const formatter = new Intl.NumberFormat("en-IN");
  const handleGotoPage = (page) => {
      if (page < 0 || page >= pageCount) {
        setErrorMessage(`No data to display`);
    } else {
      setErrorMessage("");
        setPageIndex(page);
    }
 };

 const maskMobileNumber = (number) => {
  const visibleDigits = 4; // Number of visible digits at the end
  const maskedSection = number.slice(0, -visibleDigits).replace(/\d/g, '*');
  const visibleSection = number.slice(-visibleDigits);
  return maskedSection + visibleSection;
};

const updateOrderStatus = async (orderId) => {
  console.log(orderId, approvalStatus, remark, "Comment data")
  let filterData = {
    orderId: orderId,
    status: approvalStatus,
    lenderComment : remark
  };
  setIsLoadingOn(true);
  const tempData = await postData("lender/updateOrderStatus", filterData);
  setIsLoadingOn(false);
  if (tempData.statusCode == 200) {
    setIsOpen(false);
    // getorderDetails(orderId);
    Swal.fire({
      icon: "success",
      title:"Success",
      text:tempData?.data
    })
    merchantGetData(pageIndex,pageSize)
  }
  else{
    Swal.fire({
      icon: "error",
      title:"Error!",
      text:tempData?.data?.message || "Something went wrong!!!"
    })
  }
};

  const handleSort = (column) => {
      let newOrderBy = "asc";
      console.log(column, "test")
    
    if (sortBy === column.id) {
      newOrderBy = orderBy === "asc" ? "desc" : "asc";
    }
    setSortBy(column.id);
  };

  const history = useHistory();
  async function merchantGetData(pageIndex, pageSize, option) {
    setIsLoadingOn(true);
    let apiUrl = `lender/orderDetails`;    
    setIsLoadingOn(false);
  
    let tempDocument = {
      lenderId : lenderId,
      pageSize : pageSize,
      page: pageIndex + 1,    
      searchOrder: debouncedValue,
      lenderApprovalStatus: option ? option : ""
    }; 
    try {
      const tempData = await postData(
        apiUrl,
        tempDocument
      );
        if (tempData.statusCode == 200) {
            if(option){
              setMerchantUserData(tempData.data);
              const filtered = tempData?.data?.filter((item) => item.lenderApprovalStatus === option);
              setFilteredData(filtered);
            }else{
              setFilteredData(tempData.data)
            }

          setPageCount(tempData.pagination.pageSize);
          setTotalRecords(tempData.pagination.totalRecords);
          setErrorMessage("");
        } else {
          console.log(tempData?.data?.message, "swap")
          Swal.fire({
            title:"Error!",
            text:tempData?.data?.message || "Something went wrong!!!"
          })
        }
      
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoadingOn(false);
    }
  }

  const approvedHandle = () => {
    setIsReject(false);
    setIsSuspended(true);
  }

  const Rejecthandle = () => {
    setIsReject(true);
    setIsSuspended(false);
  }

  const resetFilter = () => {
    setSearchBoxValue("")
    setCloseBtn(false)
  }

  const closeModal = () => {
    setIsOpen(false);
  };

  const updateStatus = (orderId) => {
    setUserOrderId(orderId)
    setIsOpen(true);
  };

  const handleSelectChange = (e) => {
      const optionVal = e.value;
      setOptionValSel(optionVal)
      merchantGetData(pageIndex, pageSize, optionVal)
  }
  const handleInputChange = (e) => {
    const searchVal = e.target.value;
    setSearchBoxValue(searchVal)
    if(searchVal.length > 0){
      setCloseBtn(true)
    }
};
  const [selectedImages, setSelectedImages] = useState([]);

  useEffect(() => {
    if(debouncedValue){
      merchantGetData(pageIndex, pageSize, optionValSel);
    }
    merchantGetData(pageIndex, pageSize, optionValSel);
    
  }, [pageIndex, pageSize,optionValSel, debouncedValue]);
  

  const displayMerchantInfo = (user) => {
    console.log(user, "user data")
    window.open(
      "/LeadDetailsPage?orderId=" + user.orderId +  "&userId=" + user.userId + "&orderType=" + user.orderType,
      "_blank"
    );
  };

  const orderQuotationColumns = useMemo(() => [
    {
      Header: "Sr. No.",
      accessor: (row, i) => i + 1,
      Cell: ({ cell }) => (
        <span style={{ fontWeight: "600",fontSize:"0.875rem" }}>{cell.row.index + 1}.</span>
      ),
    },
    {
      Header: "Order Id",
      accessor: "orderId",
    },
    {
      Header: "Brand",
      accessor: "brand",
      Footer: <div className="tblFooterColor">Total Amount</div>,
    },
    {
      Header: "Item",
      accessor: "item",
    },
    {
      Header: "Quantity",
      accessor: "quantity",
    },
    {
      Header: "Rate",
      accessor: "rate",
      Cell: ({ cell }) => {
        const rate = cell.row.original.rate;
        return (
          <>
            <span>{rate && rate.toFixed(2)}</span>
          </>
        );
      },
    },

    {
      Header: "Gst Applicable",
      accessor: "isGstApplicable",
      Cell: ({ cell }) => {
        const GstApplicable = cell.row.original.isGstApplicable;
        return (
          <>
            <span>{GstApplicable == 1 ? "Yes" : "No"}</span>
          </>
        );
      },
    },
    {
      Header: "Total Amount",
      accessor: "amountWithGST",

      Cell: ({ value }) => {
        const numericValue = Number(value);
        if (!isNaN(numericValue)) {
            return formatNumberWithCommasAndDecimals(numericValue);
        } else {
            return value || "-";
        }
    },
      Footer: (info) => {
        const totalAmount = info.rows.reduce(
          (sum, row) => sum + row.values.amountWithGST,
          0
        );

        return (
          <div className="tblFooterColor">
            {totalAmount && formatNumberWithCommasAndDecimals(totalAmount)}
          </div>
        );
      },
    },
    {
      Header: "Created Date",
      accessor: "OrderDetailDateTime",
      Cell: ({ cell }) => {
        return (
          <>
            <span>
              {cell.row.original.OrderDetailDateTime &&
                formatDateTimeString(cell.row.original.OrderDetailDateTime)}
              </span>
          </>
        );
      },
    },
  ]);

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, i) => i + 1 + pageIndex * pageSize,
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600",fontSize:"0.875rem" }}>{cell.row.index + 1 + pageIndex * pageSize}.</span>
        ),
      },
      {
        Header: "Order ID",
        accessor: "orderCode",
        Cell: ({ cell }) => {
          return (
            <>
              <div className="loanIdDownloadBtnDiv">
                <button
                  className="btn btn-sm someprobtn view-log-detail loanTblDownloadLoanIdBtn"
                  title="View details"
                  value={cell.row.original.orderCode}
                  onClick={() => {
                      displayMerchantInfo(cell.row.original);
                  }}
                >
                  <span style={{ fontWeight: "600" }}>
                    {cell.row.original.orderCode}
                  </span>
                </button>
              </div>
            </>
          );
        },
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: false,
      },
      {
        Header: "Business Owner Name",
        accessor: "Merchantname",
        Cell: ({ cell }) => {
          return (
            <>
              <div className="loanIdDownloadBtnDiv">
                {cell.row.original.Merchantname}
              </div>
            </>
          );
        },
      },
      {
        Header: "Business Name",
        accessor: "shopName",
      },
      {
        Header: "Mobile",
        accessor: "mobileNumber",
        Cell: ({ cell }) => {
          return (
            <>
              {maskMobileNumber(cell.row.original.mobileNumber)}
            </>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "disbursedAmount",
      },
      {
        Header: "Order Date",
        accessor: "createdDate",
      },
      {
        Header: () => <div style={{ textAlign: 'center' }}>Download</div>,
        accessor: "download",
        className: "text-center",
        Cell: ({ cell }) => {
          return (
            <>
              <div 
                className="text-center"
              >
              {cell?.row?.original?.orderAgreement &&
              <a href={cell?.row?.original?.orderAgreement}>
                <button
                    type="button"
                    style={{ fontSize: 22, color: "#6E12F9", border: "none", background: "none" }}
                    title={`Download order agreement`}
                  >
                    <FaRegFilePdf />
                  </button>
                </a>
              }
              {cell?.row?.original?.camFile &&
                <a href={cell?.row?.original.camFile}>
                <button
                  type="button"
                  style={{ fontSize: 21, color: "#6E12F9", border: "none", background: "none" }}
                  title={`Download cam File`}
                >
                  <FaRegFileExcel />
                </button>
                </a>
              }
              {cell?.row?.original?.invoicePath &&
                <a href={cell?.row?.original.invoicePath}>
                <button
                  type="button"
                  style={{ fontSize: 24, color: "#6E12F9", border: "none", background: "none" }}
                  title={`Download Invoice`}
                >
                  <TbFileInvoice />
                </button>
                </a>
              }
              </div>
            </>
          );
        },
      },{
        Header: () => <div style={{ textAlign: 'center' }}>Status</div>,
        accessor: "lenderApprovalStatus",
        Cell: ({ cell }) => {
          const status = cell.row.original.lenderApprovalStatus;
          return (
            <>
              <div className="loanIdDownloadBtnDiv text-center">
                <button
                style={{ border: "none"}}
                  // onClick={() => status === "Pending" ? updateStatus(cell.row.original.orderId) : ""}
                  className={`badge badge-pill ${
                    status === "Approved"
                      ? "badge-success"
                      : status === "Rejected"
                      ? "badge-danger"
                      : status === "Exception"
                      ? "badge-warning"
                      : "badge-primary"
                  }`}
                  value={cell.row.original.lenderApprovalStatus}
                >
                  <span style={{ fontWeight: "600" }}>
                    {cell.row.original.lenderApprovalStatus}
                  </span>
                </button>
                
              </div>
            </>
          );
        },
      },
        ],
        [pageIndex,pageSize]
    );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    // state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );
  const merchantOption = [
    { value: "Merchant Name", label: "Merchant Name" },
    { value: "Merchant Shop Name", label: "Merchant Shop Name" },
    { value: "Mobile No.", label: "Mobile No." },
    
  ];

    return (
        <div>
            {isLoadingOn ? <Loader /> : ""}
            {showTopMessage ? <TopShowMessage message="You Have Successfully Updated Details." /> : ""}
            {showTopMessage ? <TopShowMessage message="You Have Successfully Upload Order Quotation" /> : ""}
            <h3 className='tableHeaderMsg'>Invoice List</h3>
            <div className='customHeaderTable'>
                <div className="container-fluid">
                    <div className="row alignmentTable">

                      <div className="col-md-2">
                            <Select
                                placeholder="Select Status"
                                name="role"
                                options={[
                                  {label:"All", value:""},
                                  {label:"Pending", value:"Pending"},
                                  {label:"Approved", value:"Approved"},
                                  {label:"Rejected", value:"Rejected"},
                                  {label:"Exception", value:"Exception"}
                                ]}
                                onChange={(e) => handleSelectChange(e)}
                            />
                        </div>
                        <div className="col-md-2 ms-auto">
                            <input
                                type={merchantRefrenceType === "Mobile No." ? 'tel' : 'text'}
                                className='searchInputField'
                                onChange={handleInputChange}
                                placeholder='Enter Details'
                                value={searchBoxValue}
                            />
                            {closeBtn &&
                              <span style={{position:'relative', right:"25px", cursor: "pointer"}} onClick={resetFilter}>
                                {/* <button className="modalCloseBtn" > */}
                                  <FaTimes style={{fontSize:'18px'}} />
                                {/* </button> */}
                              </span>
                            }
                        </div>
                    </div>
                </div>



            </div>
            <div className='orderListDataFooter'>
                {filteredData && 
                 <ReactTable
                 columns={columns} 
                 data={filteredData} 
                 pageCount={pageCount}
                 pageIndex={pageIndex}
                 pageSize={pageSize}
                 setPageIndex={setPageIndex}
                 setPageSize={setPageSize}
                 totalRecords={totalRecords}
                 handleGotoPage={handleGotoPage}
                 errorMessage={errorMessage} 
                 handleSort={handleSort}
                 sortBy={sortBy}
                 orderBy={orderBy}
                />}    
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        <h4 className="pleaseSelectStatus">Please Select Order Status</h4>
        <div className="modalUserStatus">
          <div className="confirmationbtn mb-8">
            <div className="wrapper_btnselt">
              <button
                onClick={approvedHandle}
                htmlFor="option-3"
                className={
                  IsSuspended
                    ? "suspendedMng option option-3"
                    : "option option-3"
                }
              >
                <span onClick={() => {
                    setApprovalStatus("Approved");
                  }}>Approved</span>
              </button>

              <button
                onClick={Rejecthandle}
                htmlFor="option-2"
                className={
                  IsReject ? "rejectlisth option option-2" : "option option-2"
                }
              >
                <span onClick={() => {
                    setApprovalStatus("Rejected");
                  }}>Rejected</span>
              </button>
            </div>
          </div>
          <div className="remardi">
            <div className="form-group">
              <label htmlFor="exampleFormControlTextarea1">Financer Comment</label>
              <textarea
                onChange={(event) => {
                  setRemark(event.target.value);
                }}
                value={remark}
                className="form-control textacont"
                rows="3"
              ></textarea>
              {showAleart ? (
                <p className="requiredRemark">Remark is required...</p>
              ) : null}
            </div>
          </div>
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <button className="btn btn-success"  onClick={()=> updateOrderStatus(userOrderId)}>Submit</button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Leads;

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
        }}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table compone

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    //state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } 
  = useTable(
    {
      columns,
      data,
      // defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 10},
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy, //sort data
    usePagination //use pagination
  );

  return (
    <>
      <div>
        
      </div>
    </>
  );
}
// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
