import React, { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useLocation, useHistory } from "react-router-dom";
import "../css/sidebar.css";
import {
  FaList,
  FaUserCog,
} from "react-icons/fa";
import { LuLogOut } from "react-icons/lu";
const Lefbar = ({ handleLogout, toggleSidebar, isSidebarCollapsed }) => {

  const [openSubMenu, setOpenSubMenu] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const [isOpened, setIsOpened] = useState(false);

  const handleClick = () => {
    setIsOpened(!isOpened);
    toggleSidebar();
  };

  const handleSubMenuClick = (menuName) => {
    setOpenSubMenu((prevOpenSubMenu) =>
      prevOpenSubMenu === menuName ? null : menuName
    );
  };

  const handleItemClick = (MenuItem) => {
    if (isSidebarCollapsed) {
      setOpenSubMenu(MenuItem);
    }
  };
  useEffect(() => {
    let pageTitle = "Daqi";
    const path = location.pathname;
    if (path === "/") {
      history.push("/");
      setOpenSubMenu("Users");
      pageTitle = "Home";
    } else if (
      path.startsWith("/Leads") ||
      path.startsWith("/UsersList")
    ) {
      setOpenSubMenu("Orders");
    } else {
      setOpenSubMenu("");
    }

    // Default title
    if (path.startsWith("/Leads")) {
      pageTitle = "Leads";
    } else if (path.startsWith("/UsersList")) {
      pageTitle = "Users";
    }
    document.title = `${pageTitle} - Daqi Platform`;
  }, [location.pathname, history]);


  useEffect(() => {
    const path = location.pathname;
    if (path === "/") {
      history.push("/");
      setOpenSubMenu("Users");
    } else if (
      path.startsWith("/Leads") ||
      path.startsWith("/UsersList")
    ) {
      setOpenSubMenu("Miscllaneous");
    } else {
      setOpenSubMenu("");
    }
  }, [location.pathname, history]);

  const isActive = (path) => {
    return location.pathname === path;
  };

  const activeStyle = {
    backgroundColor: "#2e1f7a",
  };
  return (
    <div className="sidebarMainMenu">
      <Sidebar collapsed={isSidebarCollapsed}>
        <div className="hamburgerMenuMain">
          <button
            className={`menu ${isOpened ? 'opened' : ''}`}
            onClick={handleClick}
            type="button"
            aria-label="Main Menu"
          >
            <svg width={35} height={35} viewBox="0 0 100 100">
              <path
                className="line line1"
                d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
              />
              <path className="line line2" d="M 20,50 H 80" />
              <path
                className="line line3"
                d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
              />
            </svg>
          </button> 
        </div>

        <div className="logo">
          <img src="../assets/img/Daqi_logo.png" />
          <h1
            style={{
              color: "#fff",
              fontWeight: "bold",
              marginBottom: 4,
              fontSize: "30px",
              marginLeft: 20,
            }}
          >
            DAQI
          </h1>
        </div>
        <Menu>
            <MenuItem
              component={<Link to="/Leads" />}
              icon={<FaList />}
              style={isActive("/Leads") ? activeStyle : {}}
            >
              Invoice
            </MenuItem>
            {/* <MenuItem
              component={<Link to="/UsersList" />}
              icon={<FaUserCog />}
              style={isActive("/UsersList") ? activeStyle : {}}
            >
              Users
            </MenuItem> */}
          <MenuItem
            component={<Link to="/login" />}
            style={isActive("/login") ? activeStyle : {}}
            icon={<LuLogOut />}
            onClick={handleLogout}
          >
            Logout
          </MenuItem>
        </Menu>
      </Sidebar>
    </div>
  );
};

export default Lefbar;
